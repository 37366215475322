<template>
  <div class="container" @scroll.passive="getscroll($event)">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>

    <van-tabs v-model="active" sticky>
      <van-tab title="总排行" name="z">
        <LearnList :filter="0"></LearnList>
      </van-tab>
      <van-tab title="本月排行" name="a">
        <LearnList :filter="2"></LearnList>
      </van-tab>
      <van-tab title="季度排行" name="b">
        <LearnList :filter="3"></LearnList>
      </van-tab>
      <van-tab title="年度排行" name="c">
        <LearnList :filter="4"></LearnList>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import LearnList from "./components/List.vue";
export default {
  name: "LearnRankList",
  components: {
    LearnList,
  },
  data() {
    return {
      title: "学习排行榜",
      active: "z",
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getscroll(e) {
      let scrollBottom =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
      console.log(scrollBottom);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  /deep/ .van-tabs {
    height: 100%;
  }
  /deep/.van-tabs__content {
    height: 100%;
  }
  // .van-tab {
  //   height: 100%;
  // }
  // overflow-y: auto;
  /* padding-bottom: 45px; */
  /* box-sizing: border-box; */
}
// .van-tabs__content {
//   height: 100%;
// }

.van-tab__pane {
  height: 100%;
}
.loading {
  text-align: center;
}
</style>
