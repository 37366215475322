<template>
  <div class="containerlist" ref="wrapper">
    <!-- <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    > -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-row v-for="(item, index) in list" :key="item.id" class="line">
          <van-col span="2" class="number">{{ index + 1 }}</van-col>
          <van-col span="2">
            <img
              class="headimg"
              :src="item.headImgurl"
              alt=""
              @click="toUserHomePage(item.userId)"
            />
          </van-col>
          <van-col span="3">{{ item.trueName }}</van-col>
          <van-col span="9">{{ item.department }} </van-col>
          <van-col span="2" class="icon">
            <user-icon :iconstring="item.icon"></user-icon>
          </van-col>
          <van-col span="5" class="color">{{ item.totalTime }}小时</van-col>
        </van-row>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import UserIcon from "../../../views/components/userIcon";
import { learnRankListAPI } from "../../../api/school";
export default {
  props: {
    filter: Number,
  },
  components: {
    UserIcon,
  },
  data() {
    return {
      scroll: 0,
      // loading: true,
      obj: {
        current: 0,
        pageSize: 0,
        filterData: 0,
        fromType: 1,
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },

  created() {
    console.log(this);
    this.initLearnRankList();
    // this.onSearch();
    this.onSearch();
  },
  watch: {},
  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    // async initLearnRankList() {
    //   console.log(this.filter);
    //   this.obj.filterData = this.filter;
    //   //   console.log(this.obj);
    //   let result = await learnRankListAPI(this.obj);
    //   let { data } = result.data;
    //   console.log(data);
    //   this.loading = false;
    //   this.list = data;
    //   for (let i = 0; i < data.length; i++) {
    //     data[i].totalTime = (data[i].totalTime / 24).toFixed(1);
    //   }
    // },
    initLearnRankList() {
      this.obj.filterData = this.filter;
    },
    onSearch() {
      this.finished = false;
      this.list = [];
      this.obj.current = 1;
      this.obj.pageSize = 15;
      this.onLoad();
    },
    async onLoad() {
      // console.log(123);
      this.loading = true;
      let result = await learnRankListAPI({ ...this.obj });
      let { data } = result.data;
      // console.log(data);
      // console.log(result);

      this.list.push(...data);
      this.loading = false;
      for (let i = 0; i < data.length; i++) {
        data[i].totalTime = (data[i].totalTime / 60).toFixed(1);
      }
      // this.list = data;

      if (this.obj.current * this.obj.pageSize < result.data.total) {
        this.obj.current++;
      } else {
        this.finished = true;
      }

      // this.list.push(...data);
    },
    toUserHomePage(id) {
      try {
        let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
        this.scroll = wrapperScrollTop;
        this.$router.push({
          path: "/userHomepage",
          query: { para: id },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.containerlist {
  height: 100%;
  overflow-y: auto;
  /* overflow: auto; */
  /* padding-bottom: 450px; */
  box-sizing: border-box;
  position: relative;
}
.loading {
  text-align: center;
}
.line {
  margin-top: 5px;
}
.headimg {
  width: 20px;
  height: 20px;
}
.number {
  text-align: center;
  margin-top: 2px;
}
.color {
  color: rgb(254, 164, 18);
}
.icon {
  margin-bottom: 3px;
}
</style>
